const images = [{
    id: 1,
    src: "images/header.jpg",
    alt: "Image 1"
},
{
    id: 2,
    src: "images/header2.jpg",
    alt: "Image 2 "
},
{
    id: 3,
    src: "images/header3.jpg",
    alt: "Image 3"
}
];
export default images;