import HTMLFlipBook from 'react-pageflip';
import React, { useRef, useState, useEffect, ReactDOM } from 'react'
import Slider from 'react-slick'
import './App.css';
import images from "./images"
import Popup from 'reactjs-popup';

export const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="page page-cover" style={props.style} ref={ref} data-density="hard">
      <div className="page-content">
        <div>{props.children}</div>
      </div>
    </div>
  );
});

export const Page = React.forwardRef((props, ref) => {
  if (props.extra == true) {
    return (
      <div className="page" ref={ref}>
        <div className='page-content'>
          {props.children}
        </div>
      </div>
    )
  }
  if (props.image == null || props.image == "") {
    return (
      <div className="page" ref={ref}>
        <div className="page-content">
          <div className="page-header"><p>{props.header}</p></div>
          <div className="page-text">{props.children}</div>
          <div className="page-footer">{props.number}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page" ref={ref}>
        <div className="page-content">
          <div className="page-header"><p>{props.header}</p></div>
          <div className="page-image"><img src={"images/guestphotos/" + props.image} /></div>
          <div className="page-text">{props.children}</div>
          <div className="page-footer">{props.number}</div>
        </div>
      </div>
    );
  }
});

const TOC = React.forwardRef((props, ref) => {
  return (
    <ol className="toc-list">
      {/* Intro */}
      <li>
        <a href={void (0)} onClick={() => props.func(1)}>
          <span className="title">Information<span className="leaders" aria-hidden="true"></span></span>
          <span className="list-page"><span className="visually-hidden">Page&nbsp;</span>ii</span>
        </a>

        <ol>
          <li>
            <a href={void (0)} onClick={() => props.func(2)}>
              <span className="title">Table of Contents<span className="leaders" aria-hidden="true"></span></span>
              <span className="list-page"><span
                className="visually-hidden">Page&nbsp;</span>i</span>
            </a>
          </li>
        </ol>
      </li>
      {/* Us */}
      <li>
        <a href={void (0)} onClick={() => props.func(3)}>
          <span className="title">Us<span className="leaders" aria-hidden="true"></span></span>
          <span className="list-page"><span className="visually-hidden">Page&nbsp;</span>1-2</span>
        </a>

        <ol>
          <li>
            <a href={void (0)} onClick={() => props.func(3)}>
              <span className="title">Our Story<span className="leaders" aria-hidden="true"></span></span>
              <span className="list-page"><span
                className="visually-hidden">Page&nbsp;</span>1</span>
            </a>
          </li>
          <li>
            <a href={void (0)} onClick={() => props.func(4)}>
              <span className="title">Gallery<span className="leaders" aria-hidden="true"></span></span>
              <span className="list-page"><span
                className="visually-hidden">Page&nbsp;</span>2</span>
            </a>
          </li>
        </ol>
      </li>
      {/* Guest Book */}
      <li>
        <a href={void (0)} onClick={() => props.func(5)}>
          <span className="title">Guest Book<span className="leaders" aria-hidden="true"></span></span>
          <span className="list-page"><span className="visually-hidden">Page&nbsp;</span>3-7</span>
        </a>

        <ol>
          <li>
            <a href={void (0)} onClick={() => props.func(6)}>
              <span className="title">Sign Guest Book<span className="leaders" aria-hidden="true"></span></span>
              <span className="list-page"><span
                className="visually-hidden">Page&nbsp;</span>3</span>
            </a>
          </li>
          <li>
            <a href={void (0)} onClick={() => props.func(7)}>
              <span className="title">Other Guests<span className="leaders" aria-hidden="true"></span></span>
              <span className="list-page"><span
                className="visually-hidden">Page&nbsp;</span>4-7</span>
            </a>
          </li>
        </ol>
      </li>

    </ol>
  );
});

export const ImageSlider = ({ images }) => {

  const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 4000,
    vertical: true,
    arrows: false,
    centerMode: true,
    centerPadding: "50px",
    cssEase: 'ease-out',
    fade: true,
  };
  return (
    <>
      <Slider {...settings}>
        {images.map((item) => (
          <img key={item.id} src={item.src} alt={item.alt} data-url={item.src} />
        ))}
      </Slider>
    </>
  )
}

class MessageForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "", message: "", fileUpload: React.createRef(), previewSource: "",
      error: "", errorClass: "hidden",
      loadingClass: "hidden",
      formClass: "show",
      successClass: "hidden",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ ...this.state, errorClass: "hidden", loadingClass: "show", formClass: "hidden" });
    const formData = new FormData();
    formData.append('name', this.state.name);
    formData.append('message', this.state.message);
    formData.append('file', this.state.fileUpload.current.files[0]);
    await fetch('/api/guests.php', {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        //success
        if (data['response'] == 0) {
          this.setState({ ...this.state, loadingClass: "hidden", formClass: "hidden", successClass: "show" });
        } else {
          this.setState({ ...this.state, errorClass: "show", error: data['content'], loadingClass: "hidden", formClass: "show" });
        }
      }).catch((err) => {
        console.log(err);
      })
  };

  onSelectFile(e) {
    const objectUrl = URL.createObjectURL(this.state.fileUpload.current.files[0]);
    this.setState({ ...this.state, previewSource: objectUrl });
    return () => URL.revokeObjectURL(objectUrl)
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <>
        <div className={this.state.loadingClass} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 9999 }}>
          <img style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -75%)", width: "50%" }} src='/images/loading.gif' />
        </div>
        <form className={this.state.formClass} id='signature-form' onSubmit={this.handleSubmit}>
          <div className='border name-container override-mouse'>
            <label htmlFor='name'>Your Name(s)</label>
            <input type='text' className='blank' id='name' name='name' value={this.state.name} onChange={this.handleInputChange} />
          </div>

          <div className='message-container override-mouse'>
            <label htmlFor='message'>Your Message/Story</label>
            <textarea type='text' id='message' name='message' value={this.state.message} onChange={this.handleInputChange} />
          </div>

          <div className='image-container override-mouse'>
            <input id="imgsrc" type="file" name="imgsrc" ref={this.state.fileUpload} accept="image/*, video/*" value={this.state.image} onChange={this.onSelectFile} />

            <label htmlFor="imgsrc">
              <div id='selector' className="file-selector-cont">
                <i className="fa fa-download" aria-hidden="true"></i>
                <br />
                <span className="btn-primary">Select an image/video<br /><small>(optional)</small></span>
              </div>
              <div id='selected-photo'>
                <img src={this.state.previewSource} />
              </div>
            </label>
          </div>

          <div className='submit-container'>
            <p style={{ backgroundColor: "black", color: "red", position: "absolute", left: "25px", bottom: 0 }} className={this.state.errorClass}>{this.state.error}</p>
            <button className='override-mouse' type='submit' id="upload-btn">Upload</button>
          </div>
        </form>
        <div className={this.state.successClass} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
          <h2 style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "90%", textAlign: "center" }}>Thank you for signing the guest book!</h2>
        </div>
      </>
    );
  }
};

export async function loadGuests(callback) {
  await fetch('/api/guests.php', {
    method: 'GET'
  })
    .then((response) => response.json())
    .then((data) => {
      //success
      if (data['response'] == 0) {
        callback(data['content']);
      }
      else {
        console.log(data['content']);
      }
    }).catch((err) => {
      console.log(err);
    });
}

export const book = React.createRef();
export let isLoaded = false;
let pages = [];
export function setGuestPages(value) { pages = value; }
function DemoBook(props) {
  //const book = useRef(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotal] = useState(0);
  const [styles, setStyles] = useState({ transform: "translateX(-25%)", zIndex: 3, boxShadow: totalPages + "px 0 0 0 rgba(0, 0, 0, 0.5)" });
  const [contStyles, setContStyles] = useState({ zIndex: 0 });
  const [aboutStyles, setAboutStyles] = useState({ className: 'show' });

  const onTOCClick = (e) => {
    book.current.pageFlip().flip(e);
  };

  const nextButtonClick = () => {
    book.current.pageFlip().flipNext();
  };

  const prevButtonClick = () => {
    book.current.pageFlip().flipPrev();
  };

  const onPage = (e) => {
    setPage(e.data);
    setTotal(e.object.pages.pages.length);
    if (e.data === 0) {
      setStyles({ ...styles, transform: "translateX(-25%)" });
      setContStyles({ zIndex: 0 });

    } else {
      setStyles({ ...styles, transform: "translateX(0)", boxShadow: (e.object.pages.pages.length - page - 1) + "px 0 0 0 rgba(0, 0, 0, 0.5), " + -(page + 1) + "px 0 0 0 rgba(0, 0, 0, 0.5)" });
      setContStyles({ zIndex: 3 });
    }
  };

  const ImageGridItem = ({ image }) => {
    const style = {
      gridColumnEnd: `span ${getSpanEstimate(image.width)}`,
      gridRowEnd: `span ${getSpanEstimate(image.height)}`,
    }

    return <img style={style} src={image.url} alt={image.alt} />
  }

  function getSpanEstimate(size) {
    if (size > 150) {
      return 2
    }

    return 1
  }

  const onInit = (e) => {
    setTotal(e.object.pages.pages.length);
    setStyles({ ...styles, transform: "translateX(-25%)", boxShadow: (e.object.pages.pages.length) + "px 0 0 0 rgba(0, 0, 0, 0.5)" });
    isLoaded = true;
  }

  const width = window.innerWidth;

  var bookRatio = [4, 5];
  if (width <= 650) {
    bookRatio[1] = 6;
  }

  return (
    <div>
      <div className='background'>
        <img src='images/Flwoer Background 3.jpg' />
      </div>

      <div id='about' >
        <Popup
          defaultOpen={true}
          modal
        >
          {close => (
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> Ceremony Live Stream </div>
              <div className="content">
                {' '}
                <p>On Saturday, March 9th, 2024, we will be live streaming our wedding ceremony
                  for those who cannot attend in person.
                  <br />
                  <br />
                  Please join us at 5pm PST to celebrate our special day.
                  <br />
                  <br />
                  The live stream will be available at the following link:
                  <a href='https://livestream.com/luxepixel' target='_blank'> https://livestream.com/luxepixel</a>
                </p>
              </div>
            </div>
          )}
        </Popup>
        <button className='showBtn btn' onClick={() => { setAboutStyles({ className: 'show-top' }); }}>i</button>
        <div {...aboutStyles}>
          <button onClick={() => { setAboutStyles({ className: 'hide-top' }); }} className='btn closeBtn'>X</button>
          <h3>To RSVP for the new venue, please email us at <br /><a href='mailto:CoryandAlexWedding@gmail.com'>CoryandAlexWedding@gmail.com</a></h3>
          <br />
          <h2> HOW TO NAVIGATE: </h2>
          <p>Click or Tap the right side of the "book" to flip to the next page and the left side to flip to the previous page.</p>
          <p>Hint: On computer, you can also click and drag the corners of the pages to manually turn them.</p>
        </div>
      </div>

      <div className='book-content' style={contStyles}>
        <HTMLFlipBook
          width={bookRatio[0]}
          height={bookRatio[1]}
          size="stretch"
          minWidth={400}
          maxWidth={700}
          minHeight={500}
          maxHeight={600}
          maxShadowOpacity={0.5}
          showCover={true}
          disableFlipByClick={true}
          mobileScrollSupport={true}
          onFlip={onPage}
          className="demo-book"
          ref={book}
          style={styles}
          onInit={onInit}
          swipeDistance={10}
          disableMobileTurning={true}
        >

          <PageCover>
            <h2><span className='engraved'>Alex and Cory's <br />Guest Book</span></h2>
            <div className="page-image">
              <ImageSlider images={images} />
            </div>
          </PageCover>
          <Page number={"i"} header={"Wedding & Reception Information"}>
            <p style={{fontWeight: "bold", fontStyle: "normal"}}>On Saturday, March 9th, 2024, we will be live streaming our wedding ceremony
              for those who cannot attend in person.
              <br />
              <br />
              Please join us at 5pm PST to celebrate our special day.
              <br />
              <br />
              The live stream will be available at the following link:
              <a href='https://livestream.com/luxepixel' target='_blank'> https://livestream.com/luxepixel</a>
            </p>
            <br />
            <hr />
            <h4 style={{ marginBottom: "20px" }}>Please join us at our <b>new</b> date and venue:</h4>
            <p>5pm on Saturday, March 9th, 2024</p>
            <p>at the chapel at the <a href='https://maps.app.goo.gl/o3sQheUpvzQtSKkK9' target='_blank'>Aria Hotel</a> in Las Vegas, Nevada</p>
            <br />
            <hr />
            <br />
            <h3>Reception Information</h3>
            <h4 style={{ marginBottom: "20px", marginTop: "15px" }}>Afterwards, celebrate with us during our reception to follow:</h4>
            <p><a href='https://maps.app.goo.gl/cS6myJLKigiZXsN29' target='_blank'>Texas de Brazil</a></p>
            <p><a href='https://maps.app.goo.gl/cS6myJLKigiZXsN29' target='_blank'>6533 Las Vegas Blvd S, Las Vegas, NV 89119</a></p>
            <br />
            <hr />
            <br />
            <h3>Discount Information</h3>
            <h4 style={{ marginBottom: "20px", marginTop: "15px" }}>Staying in Las Vegas? Enjoy 15% off any MGM Resort Property:</h4>
            <p>Use the referral code TWEDDA and book through <a href='https://www.mgmresorts.com/en/offers/exclusive-wedding-room-offer.html?ecid=em_BB_WE_FF_BookedWedding_LV_AR' target='_blank'>MGM Resorts Website</a>.</p>
          </Page>
          <Page number={"ii"} header={"Table of Contents"}>
            <div>
              <TOC func={onTOCClick}></TOC>

              <div style={{ marginTop: "50px", width: "90%", marginLeft: "5%" }}>
                <b><p style={{ textAlign: "left" }}>Please sign our virtual guest book, especially if you will not be able to
                  join us for the ceremony. We would love stories, anecdotes about one or
                  both of us, and any words of wisdom you would like to share.</p></b>
              </div>
            </div>
          </Page>
          {/* <Page number={1} header={"Our Story"} func={onTOCClick}><p>Story still needed :D</p></Page> */}
          <Page number={2} header={"Gallery"} func={onTOCClick}>
            <div className='image-gallery'>
              <ImageGridItem image={{ url: "images/Alex & Cory at Rackham Fountain.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Alex in San Diego.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Cory - Our First Christmas.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Cory & Alex - Halloween 2019.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Cory & Alex at Hoover Dam.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Cory & Fish Cone.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Cory builds a bed.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Cory Lego Angel.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Great picture of Cory.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Laurine & Cory.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Leo, Cory, Alex.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Our Puppy Reggie.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Reggie - Hungry.jpg", alt: "Test" }} />
              <ImageGridItem image={{ url: "images/Our first christmas.jpg", alt: "Test" }} />
            </div>
          </Page>
          <Page extra={true} func={onTOCClick}>
            <MessageForm />
          </Page>
          {pages.map(page => page)}
        </HTMLFlipBook>
      </div>

      <div className="container">
        <div>

          <button type="button" onClick={prevButtonClick}>
            Previous page
          </button>

          [<span>{page}</span> of <span>{totalPages}</span>]

          <button type="button" onClick={nextButtonClick}>
            Next page
          </button>

        </div>
      </div>
    </div>
  );
}

// function App(props) {
//   const bookRef = useRef(null);
//   const flipBook = new DemoBook(props, bookRef);
//   return flipBook.render();
// }

export default DemoBook;
