import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, { PageCover, ImageSlider, loadGuests, Page, isLoaded, setGuestPages } from './App';
import images from "./images"
import reportWebVitals from './reportWebVitals';
import { renderToStaticMarkup } from "react-dom/server"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

var waitingInterval = setInterval(() => {
    if (!isLoaded) {
        console.log("Not Loaded yet");
    } else {
        clearInterval(waitingInterval);
        LoadPagesFromGuests();
    }
}, 1000);
//var guests = await loadGuests();

async function LoadPagesFromGuests() {
    var guests = await loadGuests(LoadPagesHandler);
    // console.log(guests['content']);
    //var data = JSON.parse('{"response":0,"content":[{"name":"fdsa","message":"fdsafsd","imgsrc":"fdas-05-04-07am-ddg7vmb-ed57d01d-6f8e-4afc-90c4-fc2c72a453c7.png"},{"name":"fdas","message":"","imgsrc":"fdas-05:04:07am-ddg7vmb-ed57d01d-6f8e-4afc-90c4-fc2c72a453c7.png"},{"name":"fdsa","message":"fdsafsd","imgsrc":"fdsa-05:30:43am-ddg7vmb-ed57d01d-6f8e-4afc-90c4-fc2c72a453c7.png"},{"name":"Jordan","message":":D Congrats!","imgsrc":"Jordan-05:31:33am-ddg7vmb-ed57d01d-6f8e-4afc-90c4-fc2c72a453c7.png"},{"name":"Jordan ","message":"Congrats :D","imgsrc":"Jordan -05:34:30am-ddg7vmb-ed57d01d-6f8e-4afc-90c4-fc2c72a453c7.png"}]}');
    //var htmlPages = [];
}

function LoadPagesHandler(data) {
    console.log("Callback called ", data);
    var pages = [];
    data.forEach(element => {
        console.log("Loading ", element)
        // const d = document.createElement("div");
        // document.getElementById("root").append(d);
        var p = <Page number={4 + pages.length} header={element['name']} image={element['imgsrc']}><p>{element['message']}</p></Page>
        pages.push(p);
        // const e = renderToStaticMarkup(p);
        // d.innerHTML = e;
        // htmlPages.push(d.firstChild);
    });
    //book.current.pageFlip().addFromHtml(htmlPages);
    pages.push(
        <PageCover>
          <h2><span className='engraved'>Alex and Cory's <br />Guest Book</span></h2>
          <div className="page-image">
            <ImageSlider images={images} />
          </div>
        </PageCover>);
    setGuestPages(pages);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
